export const GET_WATCHLIST = 'GET_WATCHLIST';
export const GET_ALL_WATCHLISTS = 'GET_ALL_WATCHLISTS';
export const ADD_WATCHLIST_TITLE = 'ADD_WATCHLIST_TITLE';
export const REMOVE_WATCHLIST_TITLE = 'REMOVE_WATCHLIST_TITLE';
export const DELETE_WATCHLIST = 'DELETE_WATCHLIST';
export const CREATE_WATCHLIST = 'CREATE_WATCHLIST';
export const CREATE_FAILURE = 'CREATE_FAILURE';
export const ACTIVATE_WATCHLIST = 'ACTIVATE_WATCHLIST';
export const DEACTIVATE_WATCHLIST = 'DEACTIVATE_WATCHLIST';
export const CLEAR_WATCHLIST_STATE = 'CLEAR_WATCHLIST_STATE';
